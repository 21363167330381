import { getCognitoJwt } from "./cognito";
import { cleanUserArtifacts } from "./user";
import { jwtDecode } from "jwt-decode";
import { AuthClient } from "@securityjourney/svc-auth";
import { APP_URL_HOST } from "../constants/urls";
import { AFTER_SIGN_OUT_PATH } from "../routes/landing";
import { getIsSjAdminFromStorage, getImpersonatedEmail, getCurrentSelectedTenant } from "./user";

const svcAuthClient = new AuthClient(APP_URL_HOST);

// Methods for saving/retrieving the SvcAuthToken from local storage
const LocalTokenStorage = {
  save: token => localStorage.setItem("svcAuthToken", token),
  get: () => localStorage.getItem("svcAuthToken"),
};

/**
 * Called when the app is first loaded to determine if the user is already authenticated.
 * It does this by attempting to retrieve the SvcAuthToken from local storage and checking if it is expired.
 * If it is expired, it will attempt to exchange the Cognito token for a new SvcAuthToken.
 * If successful, the new token is saved to local storage and the user is considered authenticated.
 * If unsuccessful (most likely because the Cognito refresh token is expired), the user is considered unauthenticated.
 * @returns boolean
 */
export const rehydrateUserAuthStatus = async () => {
  try {
    const token = await retrieveSvcAuthToken({ noLogOut: true });
    return !!token && !isTokenExpired(token);
  } catch (e) {
    return false;
  }
};

/**
 * Retrieve the SvcAuthToken. Handles initial token generation (via Cognito JWT exchange)
 * as well as refreshing the token when if it has expired.

 * @param {boolean} forceRefresh Pass true to force a refresh of the token 
 * @returns {Promise<string} The SvcAuthToken
 */
export const retrieveSvcAuthToken = async ({ forceRefresh = false, noLogOut = false } = {}) => {
  // First, check to see if we have an unexpired auth token.
  const currentToken = LocalTokenStorage.get();
  if (forceRefresh || !currentToken || isTokenExpired(currentToken)) {
    // Get a new token by exchanging Cognito token, passing along forceRefresh flag
    const newToken = await exchangeCognitoTokenForSvcToken({ forceRefresh, noLogOut });
    if (newToken) LocalTokenStorage.save(newToken);
    return newToken;
  } else {
    // Token exists and is not expired - return it
    return currentToken;
  }
};

export const retrieveTenantList = async () => {
  const svcToken = await retrieveSvcAuthToken();
  if (!svcToken) {
    return [];
  }
  const decodedToken = jwtDecode(svcToken);
  const tenants = decodedToken.available_tenants;
  if (!tenants) {
    return [];
  }
  return tenants.map(tenant => tenant.name);
};

export const retrieveAuthenticatedUserInfo = async () => {
  const svcToken = LocalTokenStorage.get();
  if (!svcToken) {
    return null;
  }
  const decodedToken = jwtDecode(svcToken);
  return { email: decodedToken.email, userId: decodedToken.directory_uuid };
};

export const switchAuthScopes = async scopes => {
  const newToken = await exchangeCognitoTokenForSvcToken({ scopes });
  if (newToken) LocalTokenStorage.save(newToken);
};

const isTokenExpired = token => {
  const claims = jwtDecode(token);
  if ("exp" in claims) {
    const curr = Math.floor(Date.now() / 1000);
    return claims.exp < curr;
  }

  return false;
};

const exchangeCognitoTokenForSvcToken = async (
  options = { forceRefresh: false, scopes: null, noLogOut: false }
) => {
  const jwtToken = await getCognitoJwt({
    forceRefresh: options.forceRefresh,
    noRedirect: options.noLogOut,
  });
  if (!jwtToken && !options.noLogOut) {
    // If no Cognito token, we can't get an SvcAuthToken, and user is effectively logged out
    cleanUserArtifacts();
    window.location.href = AFTER_SIGN_OUT_PATH;
  }

  const scopes = options.scopes || determineScopes();
  const token = await svcAuthClient.exchangeForAccessToken(jwtToken, scopes).promise;

  if (token?.access_token) {
    return token?.access_token;
  } else {
    console.log("Error exchanging Cognito token for SvcAuthToken", token?.message);
    throw token?.message;
  }
};

const determineScopes = () => {
  const isSjAdmin = getIsSjAdminFromStorage();
  const impersonatedEmail = getImpersonatedEmail();
  const currentSjAdminTenant = getCurrentSelectedTenant();
  const isOperatingInTenant = !!(impersonatedEmail || currentSjAdminTenant);

  return {
    admin: isSjAdmin ? currentSjAdminTenant : null,
    as: isSjAdmin ? impersonatedEmail : null,
    sjAdmin: isSjAdmin && !isOperatingInTenant ? true : null,
  };
};
