const LOGIN_PATHS = ["", "/logged-out", "/user/sign_in"];

// Methods for saving/retrieving the redirect path from local storage
const Storage = {
  saveRedirectPath: () => {
    const currentPath = window.location.pathname;
    const redirectPath = LOGIN_PATHS.includes(currentPath) ? "/" : currentPath;
    localStorage.setItem("redirectPath", redirectPath);
  },
  getRedirectPath: () => localStorage.getItem("redirectPath"),
  removeRedirectPath: () => localStorage.removeItem("redirectPath"),
};

export const savePathForRedirect = () => {
  Storage.saveRedirectPath();
};

export const getPathForRedirect = () => {
  return Storage.getRedirectPath();
};

export const removePathForRedirect = () => {
  Storage.removeRedirectPath();
};
