import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { jwtDecode } from "jwt-decode";
import { AFTER_SIGN_OUT_PATH } from "../routes/landing";
import { cleanUserArtifacts } from "./user";
import { retrieveSvcAuthToken } from "./authService";
import { cognitoPoolData } from "../constants/cognitoPoolData";

const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

/**
 * Signs in a user to Cognito using the provided tokens. Follows this up by retrieving the svcAuthToken.
 * Used after SAML authentication to get the user signed in on the browser. (caled from user model)
 *
 * @param {Object} tokens - The tokens required for Cognito sign-in.
 * @param {string} tokens.idToken - The ID token for the user.
 * @param {string} tokens.accessToken - The access token for the user.
 * @param {string} tokens.refreshToken - The refresh token for the user.
 * @returns {Promise<void>} A promise that resolves when the sign-in process is complete.
 */
export const doCognitoSignIn = async tokens => {
  const email = getEmailFromJWT(tokens.idToken);

  const cognitoUserSession = new CognitoUserSession({
    IdToken: new CognitoIdToken({ IdToken: tokens.idToken }),
    AccessToken: new CognitoAccessToken({ AccessToken: tokens.accessToken }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: tokens.refreshToken }),
  });

  getCognitoUser({ email }).setSignInUserSession(cognitoUserSession);

  await retrieveSvcAuthToken();
};

/**
 *
 * @param {Object} credentials - The credentials for the user.
 * @param {string} credentials.email - User's email
 * @param {string} credentials.password - User's password
 * @returns
 */
export const cognitoPasswordAuth = async ({ email, password }) => {
  const userData = {
    Username: email.toLowerCase(),
    Password: password,
  };

  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(userData);
  const cognitoUser = getCognitoUser({ email });
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async () => {
        await retrieveSvcAuthToken();
        resolve();
      },
      onFailure: err => reject(err.message),
    });
  });
};

/**
 * Signs up a user in Cognito for username/password authentication.
 * @param {Object} options - Options for the cognitoSignup function.
 * @param {string} options.password - The password for the new user.
 * @param {string} options.email - The email for the new user.
 * @param {Function} options.cb - A callback function to call with any errors.
 * @param {Function} options.success - A callback function to call with a success message.
 */
export const cognitoSignup = ({ password, email, cb, success }) => {
  try {
    const errors = {};
    let invalid = false;
    const fields = { password, email };
    for (const field in fields) {
      if (!fields[field]) {
        invalid = true;
        errors[field] = "Can't be blank";
      }
    }
    if (invalid) {
      throw { response: { data: { errors } } };
    }

    const userPool = getCognitoUserPool();
    const attributeList = [];
    const dataEmail = {
      Name: "email",
      Value: email.toLowerCase(),
    };
    const attributeEmail = new CognitoUserAttribute(dataEmail);

    attributeList.push(attributeEmail);

    userPool.signUp(email.toLowerCase(), password, attributeList, null, (err, result) => {
      if (err) {
        cb(err.message);
        return;
      }

      success("We have sent you an email to verify your account", result.user);
    });
  } catch (err) {
    cb(err.message);
  }
};

/**
 * Called from authService helper to get the current Cognito JWT token.
 * If the token is expired, it will attempt to refresh it.
 * @param {Object} options - Options for the getCognitoJwt function.
 * @param {Boolean} options.forceRefresh - Force a refresh regardless of token expiration.
 * @param {string} options.noRedirect - Don't redirect to the sign-out page if the user is not authorized.
 * @returns {Promise<string>} A promise that resolves with the JWT token for the current user.
 */
export const getCognitoJwt = async ({ forceRefresh = false, noRedirect = false }) => {
  let token = null;
  const user = getCognitoCurrentUser();
  if (!user) {
    // No current user, so no Jwt to return
    return null;
  }
  try {
    const session = await getUserSession(user);
    token = session.getIdToken();

    // If forceRefresh OR the current token is expired, try to get a new one
    const now = new Date();
    if (forceRefresh || now.getTime() > token.getExpiration() * 1000) {
      token = await refreshCognitoUserSession(user, session.getRefreshToken());
    }
  } catch (e) {
    if (e.name === "NotAuthorizedException") {
      console.log("Caught NotAuthorizedException while getting user session - logging out");
      if (!noRedirect) {
        cognitoLogout();
        cleanUserArtifacts();
        window.location.href = AFTER_SIGN_OUT_PATH;
      }
    }
    return null;
  }

  return token.getJwtToken();
};

/**
 * Get current Cognito user
 * @returns {CognitoUser} The current Cognito user.
 */
export const getCognitoCurrentUser = () => {
  return getCognitoUserPool().getCurrentUser();
};

/**
 * Log the current user out of Cognito.
 */
export const cognitoLogout = () => {
  const user = getCognitoCurrentUser();
  if (user) {
    user.signOut();
  }
};

/**
 * Get the current Cognito user's session.
 * @param {CognitoUser} user - The Cognito user to get the session for.
 * @returns
 */
const getUserSession = user => {
  return new Promise((resolve, reject) => {
    user.getSession((err, session) => {
      if (err) return reject(err);

      resolve(session);
    });
  });
};

const refreshCognitoUserSession = async (cognitoUser, refresh_token) => {
  return new Promise(resolve => {
    cognitoUser.refreshSession(refresh_token, (err, session) => {
      if (err) {
        console.log(err);
        cognitoLogout();
        cleanUserArtifacts();
        window.location.href = AFTER_SIGN_OUT_PATH;
      } else {
        let idToken = session.getIdToken();
        resolve(idToken);
      }
    });
  });
};

export const getCognitoUser = ({ email }) => {
  const userPool = getCognitoUserPool();

  const userData = {
    Username: decodeURIComponent(email.toLowerCase()),
    Pool: userPool,
  };

  return new CognitoUser(userData);
};

/**
 * Get a Cognito user pool object.
 * @returns {CognitoUserPool} The Cognito user pool.
 */
const getCognitoUserPool = () => {
  return new CognitoUserPool(cognitoPoolData());
};

const getEmailFromJWT = jwt => {
  const claims = jwtDecode(jwt);

  if ("email" in claims) {
    return claims.email;
  } else if (
    "cognito:username" in claims &&
    claims["cognito:username"].includes("@") &&
    claims["cognito:username"].includes(".")
  ) {
    const username = claims["cognito:username"];

    const i = username.indexOf("_");
    const emailParts = [username.slice(0, i), username.slice(i + 1)];

    if (emailParts.length > 1) {
      return emailParts[1];
    }
    return emailParts[0];
  }

  return null;
};
