import i18next from "i18next";
import getClient from "../client";
import { toast } from "react-toastify";
import {
  apiCurrentUserPath,
  API_EXPORT_USER_DATA_PATH,
  API_UPDATE_CURRENT_USER_PATH,
  API_CHECK_LICENSE_PATH,
} from "../routes/user";
import { goToAdminHome, goToHome } from "../helpers/pages";
import {
  cleanUserArtifacts,
  setIsSjAdminToStorage,
  getIsSjAdminFromStorage,
  removeCurrentSelectedTenant,
  removeImpersonatedEmail,
  saveSelectedTenant,
} from "../helpers/user";
import {
  retrieveTenantList,
  switchAuthScopes,
  rehydrateUserAuthStatus,
} from "../helpers/authService";

import { doCognitoSignIn, cognitoLogout, cognitoPasswordAuth } from "../helpers/cognito";

import { AFTER_SIGN_OUT_PATH } from "../routes/landing";

import { getDirectoryClient } from "../helpers/serviceClients/directoryService";

const DEFAULT_LOGIN_ERROR = "Error logging in. Please try again.";

const UNLICENSED_LOGIN_ERROR =
  "You are not currently licensed. Please get in touch with your administrator to access Security Journey.";

export default {
  state: {
    // auth
    isAuthenticated: await rehydrateUserAuthStatus(),

    isLoaded: false,

    duo: null,

    // attrs
    id: null,
    email: "",
    name: "",
    firstName: "",
    lastName: "",
    locale: null,
    title: "",
    company: "",
    jobRole: "",
    country: "",
    statusEmail: false,
    consent: false,
    currentRank: null,
    trial: true,
    role: "",
    certFirstName: "",
    certLastName: "",

    // image
    gravatarId: "",
    smallImageUrl: "",
    thumbImageUrl: "",

    // map
    journeymapScaleIndex: 1,
    journeymapType: "map",
    listViewMode: null,
    openedGuides: [],

    //interface
    interfaceType: "map",

    // stats
    rating: 0,
    totalPoints: 0,

    // module
    moduleViewMode: "video",

    latestChangesLastDateViewed: "",

    // boolean
    canSelectRoles: false,
    hasAccessToDojoActivities: false,
    hasCurrentCourse: false,
    isAssessmentMessageShown: false,
    isDojoPreviewShown: false,
    isParticipantOfLeaderboard: false,
    isParticipantOfAchievement_wall: false,
    newFeatureTourCompleted: false,
    breakFixFeatureTourCompleted: false,
    signupSurveyCompleted: true,
    showAdminTour: false,
    showJourneyTour: false,
    showJourneyIntroVideo: false,
    showLearnTour: false,
    showBreakFixTour: false,

    // SJ Admin Roles
    hasReadOnly: false,
    hasReadWriteOnly: false,
    hasCsmOnly: false,
    hasContentOnly: false,
    hasSuperOnly: false,
    hasCloneAccess: true,

    // visible options
    canSeeWhiteButton: false,
    canSeeWhiteShadow: false,
    canSeeYellowButton: false,
    canSeeYellowShadow: false,
    canSeeGreenButton: false,
    canSeeGreenShadow: false,

    // current level
    currentLevel: {
      activeRolesNumber: null,
      id: null,
      name: "",
      rankName: "",
      statsProgress: 0,
      statsCurrent: 0,
      statsTotal: 0,
      userRole: null,
    },

    // passed roles
    passedBelts: [],

    // last passed enrollment
    passedEnrollment: {
      id: null,
      completedAtDate: null,
      completionShowModal: false,
      levelId: null,
      levelName: "",
      levelRankName: "",
      levelDescription: "",
      levelHasDojoActivities: false,
      role: null,
    },
    videoSettings: {},
    isUpdating: false,
    recordsPerPage: null,
    loginErrorMessage: "",
    selectedEntity: {},
    isScorm: false, // NOTE: we should probably change this variable name when we implement 1st Party Scorm
  },

  reducers: {
    set: (state, payload) => ({ ...state, ...payload }),

    setSignupSurveyCompleted: (state, signupSurveyCompleted) => ({
      ...state,
      signupSurveyCompleted,
    }),

    update: (state, payload) => ({ ...state, isUpdating: false, ...payload }),
    multipleUpdate: (state, payload) => ({ ...state, ...payload }),
    // used in userProfile
    setName: (state, name) => ({ ...state, name }),

    updateImage: (state, { smallImageUrl, thumbImageUrl }) => ({
      ...state,
      smallImageUrl,
      thumbImageUrl,
    }),

    makeAssessmentMessageShown: state => ({
      ...state,
      isAssessmentMessageShown: true,
    }),

    scormReset: (state, payload) => payload,
  },

  effects: dispatch => ({
    async trackTime({ component, duration, params, timePath }) {
      const users = {
        component,
        duration,
        parameters: params,
      };
      await getClient().post(timePath, { users });
    },

    async exportUser() {
      const { data } = await getClient().get(API_EXPORT_USER_DATA_PATH);
      const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
      const elem = document.getElementById("downloadAnchorElem");
      elem.setAttribute("href", dataStr);
      elem.setAttribute("download", "user.json");
      elem.click();
    },

    async loadCurrent() {
      const {
        data: {
          currentLevel,
          lastPassedEnrollment: passedEnrollment,
          passedBelts,
          user,
          visibleOptions,
        },
      } = await getClient().get(
        apiCurrentUserPath([
          "current_level",
          "last_passed_enrollment",
          "passed_belts",
          "user",
          "visible_options",
        ])
      );

      if (currentLevel) {
        dispatch.user.update({ currentLevel });
        dispatch.user__currentCourse.updateStats(currentLevel);
      }

      if (passedEnrollment) {
        dispatch.user.update({ passedEnrollment });
      }

      if (passedBelts) {
        const updatedPassedBelts = passedBelts.map(pb => ({ ...pb, isContextOpen: false }));
        dispatch.user.update({ passedBelts: updatedPassedBelts });
      }

      if (user) {
        dispatch.user.update({ ...user });
      }

      if (visibleOptions) {
        dispatch.user.update({ ...visibleOptions });
      }

      dispatch.user.update({ isLoaded: true });
    },

    async updateModuleViewMode(moduleViewMode) {
      const userParams = { user: { moduleViewMode } };
      dispatch.user.update({ moduleViewMode });
      const { data } = await getClient().put(API_UPDATE_CURRENT_USER_PATH, userParams);

      const { user } = data || {};
      if (user) {
        dispatch.user.update({
          moduleViewMode: user.moduleViewMode || "video",
        });
      }
    },

    async loadAdmin() {
      const {
        data: { user },
      } = await getClient().get(apiCurrentUserPath(["admin"]));

      if (user) {
        dispatch.user.update({ ...user });
        dispatch.user.update({ hasReadOnly: user.role === "sj_admin_read_only" });
        dispatch.user.update({ hasReadWriteOnly: user.role === "sj_admin_read_write" });
        dispatch.user.update({ hasCsmOnly: user.role === "sj_admin_csm" });
        dispatch.user.update({ hasContentOnly: user.role === "sj_admin_content" });
        dispatch.user.update({ hasSuperOnly: user.role === "sj_super_admin" });
      }

      dispatch.user.update({ isLoaded: true });
    },

    async loginWithSAML({ path, code, isSjAdmin = false }) {
      try {
        dispatch.user.set({ loginErrorMessage: "" });
        const { data: tokensData } = await getClient({ noAuth: true }).post(path, {
          code,
          sj_admin: isSjAdmin,
          tenantId: null,
          local: document.location.hostname === "localhost",
        });

        if (isSjAdmin) {
          setIsSjAdminToStorage();
        }

        // Sign in to Cognito
        await doCognitoSignIn(tokensData);

        // Do post-login processes
        await dispatch.user.postLogin();
      } catch (error) {
        const message = error.message || DEFAULT_LOGIN_ERROR;
        dispatch.user.set({ loginErrorMessage: message });
        throw error;
      }
    },

    async loginWithPassword({ email, password }) {
      try {
        await cognitoPasswordAuth({ email, password });
      } catch (error) {
        let errorMessage = DEFAULT_LOGIN_ERROR;
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            errorMessage = error.response.data.message;
          } else if (error.response.data.error) {
            errorMessage = error.response.data.error;
          }
        } else {
          errorMessage = error;
        }
        dispatch.user.set({ loginErrorMessage: errorMessage });
        throw error;
      }

      // Do post-login processes
      await dispatch.user.postLogin();
    },

    async postLogin() {
      try {
        const isSjAdmin = getIsSjAdminFromStorage();

        // If NOT SJ-ADMIN:
        // After Cognito signin, check to see if there's only one available tenant.
        // If so, set that as the current tenant
        if (!isSjAdmin) {
          const tenantList = await retrieveTenantList();
          if (tenantList.length === 1) {
            const selectedTenant = tenantList[0];
            saveSelectedTenant(selectedTenant);
            dispatch.site.setSelectedTenant(selectedTenant);
            // After selecting the tenant, check that the user is licensed
            await dispatch.user.checkUserLicense(selectedTenant);
          }
        }

        // let app know that we are authenticated
        dispatch.user.set({ isAuthenticated: true });
      } catch (error) {
        const message = error.message || DEFAULT_LOGIN_ERROR;
        dispatch.user.set({ loginErrorMessage: message });
        dispatch.user.clearUserInfo();
        throw error;
      }
    },

    signOut() {
      dispatch.user.clearUserInfo();
      window.location.href = AFTER_SIGN_OUT_PATH;
    },

    clearUserInfo() {
      if (dispatch.user__courses) {
        dispatch.user__courses.clearData(true);
      }

      cognitoLogout();
      cleanUserArtifacts();
      dispatch.user.update({
        isAuthenticated: false,
        id: null,
      });
    },

    async checkUserLicense(selectedTenant) {
      const licenseData = await getClient().post(API_CHECK_LICENSE_PATH, {
        tenant_name: selectedTenant,
      });
      if (!licenseData.data.licensed) {
        throw new Error(UNLICENSED_LOGIN_ERROR);
      }
    },

    async stopImpersonating(tenantName) {
      /** get back tenant access to go to admin home with a correct tenant access after the stopImpersonating */
      await switchAuthScopes({ sjAdmin: true, admin: tenantName });
      removeImpersonatedEmail();
      goToAdminHome();
    },

    async switchTenantSvcAuth({ tenantName }) {
      // After selecting the tenant, check that the user is licensed
      try {
        await dispatch.user.checkUserLicense(tenantName);
        saveSelectedTenant(tenantName);
        goToHome();
      } catch (e) {
        toast.error(UNLICENSED_LOGIN_ERROR);
      }
    },

    async leaveVd() {
      //* get a new svc auth token without an impersonating role or a tenant access */
      const isSjAdmin = localStorage.getItem("isSJAdmin");
      removeCurrentSelectedTenant();
      removeImpersonatedEmail();

      await switchAuthScopes({ sjAdmin: isSjAdmin });
      goToAdminHome();
    },

    async multipleUpdateAttribute(body) {
      const { params, useToast = true, shouldMerge = false } = body;
      const { data } = await getClient({ useToast }).put(API_UPDATE_CURRENT_USER_PATH, {
        user: params,
        merge: shouldMerge,
      });
      const { user } = data || {};

      dispatch.user.multipleUpdate(user);
    },

    async updateAttribute({ name, value, cb, shouldUpdate = true }) {
      try {
        dispatch.user.update({ isUpdating: true });
        let params = { user: { [name]: value } };
        const { data } = await getClient().put(API_UPDATE_CURRENT_USER_PATH, params);
        const { user } = data || {};

        if (shouldUpdate) {
          dispatch.user.update({ [name]: user[name] });
        }

        if (cb) {
          cb(""); // remove error message on success
        }
      } catch (err) {
        const { response } = err;

        let errorMessage = i18next.t("user.errors.somethingWentWrong");

        if (response && response.data && response.data.errors) {
          const { errors } = response.data;

          if (Array.isArray(errors)) {
            errorMessage = errors[0];
          } else if (typeof errors === "object" && errors !== null) {
            errorMessage = Object.entries(errors).join(", ");
          } else {
            errorMessage = errors;
          }
        }

        if (cb) {
          cb(errorMessage);
        }
      }
    },

    async updateUser({ email, firstName, lastName, jobRole, title, country, company }) {
      const svcDirectoryClient = getDirectoryClient();
      try {
        const { data: userProfile, error } = await svcDirectoryClient.updateSelf(
          email,
          firstName,
          lastName,
          {
            jobRole: jobRole,
            title: title,
            country: country,
            company: company,
          }
        );
        if (error) {
          throw error;
        }
        const user =
          {
            email: userProfile.email,
            firstName: userProfile.name.givenName,
            lastName: userProfile.name.familyName,
            jobRole: userProfile.attributes.jobRole,
            title: userProfile.attributes.title,
            company: userProfile.attributes.company,
            country: userProfile.attributes.country,
          } || {};
        dispatch.user.multipleUpdate(user);
      } catch (e) {
        toast.error(e.response.data.message);
      }
    },

    async toggleAdminTour(showAdminTour) {
      dispatch.user.update({ showAdminTour });
    },

    async toggleJourneyTour(showJourneyTour) {
      dispatch.user.update({ showJourneyTour });
    },

    async toggleLearnTour(showLearnTour) {
      dispatch.user.update({ showLearnTour });
    },

    async toggleBreakFixTour(showBreakFixTour) {
      dispatch.user.update({ showBreakFixTour });
    },

    async toggleJourneyIntroVideo(showJourneyIntroVideo) {
      dispatch.user.update({ showJourneyIntroVideo });
    },

    openAchievementContext(id, state) {
      const { passedBelts } = state.user;
      const updatedPassedBelts = passedBelts.map(pb => {
        if (pb.id === id) {
          return { ...pb, isContextOpen: !pb.isContextOpen };
        }
        return { ...pb, isContextOpen: false };
      });
      dispatch.user.update({ passedBelts: updatedPassedBelts });
    },
    outsideAchievementContext(id, state) {
      const { passedBelts } = state.user;
      const updatedPassedBelts = passedBelts.map(pb => {
        if (pb.id === id) {
          return { ...pb, isContextOpen: false };
        }
        return pb;
      });
      dispatch.user.update({ passedBelts: updatedPassedBelts });
    },
    setLoggedIn() {
      dispatch.user.set({ isAuthenticated: true });
    },

    updateListViewMode(listViewMode) {
      dispatch.user.set({ listViewMode });
    },

    setLoginErrorMessage(errorMessage = DEFAULT_LOGIN_ERROR) {
      dispatch.user.set({ errorMessage });
    },
    updateSelectedEntity(selectedEntity) {
      dispatch.user.set({ selectedEntity });
    },

    addOpenedGuides(type, state) {
      const { openedGuides } = state.user;
      const updatedOpenedGuides = [...openedGuides, type];
      dispatch.user.set({ openedGuides: updatedOpenedGuides });
    },

    removeOpenedGuides(type, state) {
      const { openedGuides } = state.user;
      const updatedOpenedGuides = openedGuides.filter(g => g !== type);
      dispatch.user.set({ openedGuides: updatedOpenedGuides });
    },
  }),
};
