import React, { useCallback, useEffect } from "react";
import T from "prop-types";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";

import EmptyPageLoader from "./components/EmptyPageLoader";
import ToastFlashes from "./components/ToastFlashes";

import {
  API_HE_LOGIN_PATH,
  API_SAML_LOGIN_PATH,
  API_SAML_SJ_ADMIN_LOGIN_PATH,
  API_SJ_ADMIN_LOGIN_PATH,
  SJ_ADMIN_SIGN_IN_PATH,
} from "./routes/landing";
import { ADMIN_BASE } from "./admin/routes";

const SUCCESS_REDIRECT_MODES = {
  user: { path: API_HE_LOGIN_PATH, redirectPath: "/" },
  sjAdmin: { path: API_SJ_ADMIN_LOGIN_PATH, redirectPath: ADMIN_BASE },
  samlUser: { path: API_SAML_LOGIN_PATH, redirectPath: "/" },
  samlSjAdmin: { path: API_SAML_SJ_ADMIN_LOGIN_PATH, redirectPath: ADMIN_BASE },
};

const ERROR_REDIRECT_MODES = {
  user: "/",
  sjAdmin: SJ_ADMIN_SIGN_IN_PATH,
  samlUser: "/",
  samlSjAdmin: SJ_ADMIN_SIGN_IN_PATH,
};

const HeRedirectApp = ({
  loginWithSAML,
  clearUserInfo,
  mode,
  loginErrorMessage,
  setLoginErrorMessage,
}) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const onLogin = useCallback(async () => {
    const { code } = qs.parse(search);
    if (!code) {
      return setLoginErrorMessage(true);
    }

    // figure out which login path & redirect to use based on the login mode
    const { path = API_HE_LOGIN_PATH, redirectPath = "/" } = SUCCESS_REDIRECT_MODES[mode];
    const isSjAdmin = window.location.pathname.includes("sj_admin_auth");

    // ensure user info is cleared before we login
    clearUserInfo();

    // Log in by exchanging the SAML auth code for the Cognito tokens
    await loginWithSAML({
      path,
      code,
      isSjAdmin,
    });
    navigate(redirectPath);
  }, [loginWithSAML, search]);

  useEffect(() => {
    if (!loginErrorMessage) {
      return;
    }

    clearUserInfo();
    const redirectTo = ERROR_REDIRECT_MODES[mode] || "/";
    navigate(redirectTo);
  }, [loginErrorMessage]);

  useEffect(() => {
    onLogin();
  }, []);

  return (
    <>
      <EmptyPageLoader />
      <ToastFlashes />
    </>
  );
};

HeRedirectApp.propTypes = {
  loginWithSAML: T.func.isRequired,
  clearUserInfo: T.func.isRequired,
  mode: T.string.isRequired,
  loginErrorMessage: T.string.isRequired,
  setLoginErrorMessage: T.func.isRequired,
};

const mapState = ({ user: { loginErrorMessage } }) => ({
  loginErrorMessage,
});

const mapDispatch = ({ user: { loginWithSAML, clearUserInfo, setLoginErrorMessage } }) => ({
  loginWithSAML,
  clearUserInfo,
  setLoginErrorMessage,
});

export default connect(mapState, mapDispatch)(HeRedirectApp);
